import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faLaptop,
  faExchangeAlt
} from '@fortawesome/free-solid-svg-icons';

class Services extends Component {
  render() {
    return (
      <Container>
        <div className='section'>
          <h3>Our Services</h3>
          <div>
            <Row>
              <Col md={4}>
                <div className='services-card'>
                  <FontAwesomeIcon
                    className='icon services-icon'
                    icon={faExchangeAlt}
                  />

                  <h5>Software Development</h5>
                  <p>
                    Design and develop web and mobile softwares that will
                    automate your business and increase revenue
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className='services-card'>
                  <FontAwesomeIcon
                    className='icon services-icon'
                    icon={faLaptop}
                  />
                  <h5>Website Design & Development</h5>
                  <p>
                    Design and develop a killer website for your business. Make
                    you known all over the world by integrating your business
                    with SEOs
                  </p>
                  <p></p>
                </div>
              </Col>
              <Col md={4}>
                <div className='services-card'>
                  <FontAwesomeIcon
                    className='icon services-icon'
                    icon={faChartLine}
                  />
                  <h5>Dashboard & Analytics</h5>
                  <p>
                    Develop Operational Dashboards Qlik Reporting Data
                    Aggregation Data Visualizations. Integrate with Cloud
                    platforms such GCP, AWS and Azure.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    );
  }
}

export default Services;
