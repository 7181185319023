import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faProjectDiagram,
  faCloudUploadAlt,
  faUndoAlt
} from '@fortawesome/free-solid-svg-icons';

class About extends Component {
  render() {
    return (
      <Container>
        <div className='section'>
          <h3>About us</h3>
          <p className='about-us'>
            Eramstech is a principal-centered software development and
            consulting company. We pride ourselves in providing robust and
            secure software solutions for your business. We create classy
            websites designed specifically to meet your business’s needs.
          </p>
          <Row>
            <Col md={6} className='about-img'></Col>
            <Col md={6}>
              <div className='about-details'>
                {/* <span className='top'></span> */}
                <div className='about-card'>
                  <FontAwesomeIcon
                    className='icon dev'
                    icon={faProjectDiagram}
                  />
                  <div>
                    <h4>Business-Oriented Development</h4>

                    <p>
                      Delivering the most cost-effective software solutions that
                      meet your business needs.
                    </p>
                  </div>
                </div>
                <div className='about-card'>
                  <FontAwesomeIcon
                    className='icon custom'
                    icon={faCloudUploadAlt}
                  />
                  <div>
                    <h4>CUSTOM SOFTWARE & APPLICATIONS</h4>
                    <p>
                      Whether you need a manual process digitized, updates to
                      legacy apps, or an entirely new solution built – we’re
                      here to help.
                    </p>
                  </div>
                </div>
                <div className='about-card'>
                  <FontAwesomeIcon className='icon agile' icon={faUndoAlt} />
                  <div>
                    <h4>AGILE DEVELOPMENT PROCESS</h4>

                    <p>
                      An ongoing collaboration with consistent demos to ensure
                      we deliver the best solution for your specific needs. 
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default About;
