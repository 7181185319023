import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';
const Footer = () => {
  return (
    <Container>
      <div className='footer-text'>
        <p>EramsTech Solutions Group &copy; {new Date().getFullYear()}</p>
      </div>
      <Link
        to='home'
        smooth={true}
        duration={900}
        className='footer-icon'
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </Link>
    </Container>
  );
};

export default Footer;
