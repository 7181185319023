import React from 'react';
import { Container, Button, Row, Col, Alert, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarker
} from '@fortawesome/free-solid-svg-icons';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      emailBody: '',
      message: ''
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    console.log(value);
    this.setState({
      [name]: value
    });
  };

  handleAlertClose = () => {
    this.setState({
      message: ''
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name, email, subject, emailBody } = this.state;
    const body = { name, email, subject, message: emailBody };
    fetch('https://erams-email-server.herokuapp.com/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json)
      .then(data => {
        this.setState({
          name: '',
          email: '',
          subject: '',
          emailBody: '',
          message: 'Thank you for reaching out, your email has been sent'
        });
      })
      .catch(error => console.log(error));
  };

  render() {
    const { name, email, subject, message, emailBody } = this.state;
    const disabled =
      name === '' ||
      email === '' ||
      subject === '' ||
      emailBody === '' ||
      emailBody.trim().split('').length === 0

    return (
      <Container>
        <div className='section'>
          <h3>Contact Us</h3>
          <Row className='contact-form'>
            <Col md={6} className='contact-info'>
              <h5>EramsTech Solutions Group.</h5>
              <p>
                <FontAwesomeIcon className='location-icon' icon={faMapMarker} />{' '}
                Blessed House, Thika Road, Opp Garden City Mall.
              </p>
              <p>
                <FontAwesomeIcon className='location-icon' icon={faPhoneAlt} />{' '}
                (+254) 0735 335 569
              </p>
              <p>
                <FontAwesomeIcon className='location-icon' icon={faEnvelope} />{' '}
                eramstech@gmail.com
              </p>
            </Col>
            <Col>
              {message && (
                <Alert
                  variant='success'
                  onClose={this.handleAlertClose}
                  dismissible
                >
                  <p>{message}</p>
                </Alert>
              )}
              <Form onSubmit={this.handleSubmit}>
                <Form.Group as={Row}>
                  <Form.Label column md={4}>
                    Name:
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      id='name'
                      onChange={this.handleChange}
                      value={name}
                      name='name'
                      type='text'
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor='email'>
                    Email:
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      id='email'
                      onChange={this.handleChange}
                      value={email}
                      name='email'
                      type='email'
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor='subject'>
                    Subject:
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      id='subject'
                      onChange={this.handleChange}
                      value={subject}
                      name='subject'
                      type='text'
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column md={4} htmlFor='message'>
                    Message:
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      as='textarea'
                      onChange={this.handleChange}
                      value={emailBody}
                      name='emailBody'
                      rows='3'
                      required
                    />
                  </Col>
                </Form.Group>
                <div className='form-button'>
                  <Button
                    type='submit'
                    style={{ width: '200px' }}
                    disabled={disabled}
                  >
                    Send
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default Contact;
