import React from 'react';
import './App.css';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import Header from './components/Header';
import About from './components/About';
import Process from './components/Process';
import Services from './components/Services';
import Footer from './components/Footer';
import Contact from './components/Contact';

function App() {
  return (
    <Container fluid>
      <Row id='home'>
        <Header />
      </Row>
      <Row  id='about' className='about'>
        <About />
      </Row>
      <Row id='process' className='process'>
        <Process />
      </Row>
      <Row id='services' className='services'>
        <Services />
      </Row>
      <Row id='contact' className='contact'>
        <Contact />
      </Row>
      <Row className='footer'>
        <Footer />
      </Row>
    </Container>
  );
}

export default App;
