import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCode,
  faPlayCircle,
  faPhoneAlt,
  faChevronCircleRight,
  faBolt,
  faChartLine
} from '@fortawesome/free-solid-svg-icons';
class Process extends Component {
  render() {
    return (
      <Container>
        <div className='section'>
          <h3>Our Process</h3>
          <Row className='our-process'>
            <Col md={2} className='process-card'>
              <FontAwesomeIcon className='icon process-icon' icon={faPlayCircle} />
              <h4>Planning</h4>
              <p>
                Collaborative meetings to understand the nature of your business
                needs.
              </p>
            </Col>
            <Col md={2} className='process-card'>
              <FontAwesomeIcon className='icon process-icon' icon={faChartLine} />

              <h4>Options Analysis</h4>
              <p>
                Providing you alternative solutions for engineering the
                technology
              </p>
            </Col>
            <Col md={2} className='process-card'>
              <FontAwesomeIcon className='icon process-icon' icon={faBolt} />

              <h4>System Design</h4>
              <p>
                Creating the user interface and mapping all systems that will be
                integrated
              </p>
            </Col>
            <Col md={2} className='process-card'>
              <FontAwesomeIcon className='icon process-icon' icon={faCode} />
              <h4>Implementation</h4>
              <p>
                Building out the agreed upon solution, creating MVP and
                iterating based on the feedback
              </p>
            </Col>
            <Col md={2} className='process-card'>
              <FontAwesomeIcon className='icon process-icon' icon={faChevronCircleRight} />

              <h4>Testing & Integration</h4>
              <p>
                Testing if the system meets business needs and integrating to
                the business.
              </p>
            </Col>
            <Col md={2} className='process-card'>
              <FontAwesomeIcon className='icon process-icon' icon={faPhoneAlt} />

              <h4>Maintenance</h4>
              <p>
                Continuous engagement of this process until you’re completely
                satisfied with your solution
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default Process;
