import React from 'react';
import { Button, Nav, Navbar, NavbarBrand, Container } from 'react-bootstrap';
import { Link } from 'react-scroll';

class Header extends React.Component {
  render() {
    return (
      <header>
        <Container>
          <Navbar expand='lg'>
            <NavbarBrand className='mr-auto'>
              <h1 className='logo'>EramsTech</h1>
            </NavbarBrand>
            <Navbar.Collapse
              id='basic-navbar-nav'
              className='justify-content-end'
            >
              <Nav className='nav-item '>
                <Nav.Item>
                  <Link
                  style={{color: '#fff'}}
                    to='home'
                    smooth={true}
                    duration={900}
                    className='nav-link'
                  >
                    Home
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    to='about'
                    smooth={true}
                    duration={900}
                    className='nav-link'
                  >
                    About
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    to='process'
                    smooth={true}
                    duration={900}
                    className='nav-link'
                  >
                    Process
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    to='services'
                    smooth={true}
                    duration={900}
                    className='nav-link'
                  >
                    Services
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    to='contact'
                    smooth={true}
                    duration={900}
                    className='nav-link'
                  >
                    Contact
                  </Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
        <div className='header-content'>
          <h1 className='heading-primary'>EramsTech Solutions Group</h1>
          <h2 className='heading-secondary'>Automate Your Business with a Robust Sotfware Today</h2>
          <Link
            to='contact'
            spy={true}
            smooth={true}
            duration={900}
            className='nav-link'
          >
            <Button variant='outline-primary' className='btn btn-contact'>
              Contact Us
            </Button>
          </Link>
        </div>
      </header>
    );
  }
}

export default Header;
